import React from 'react';
import PropTypes from 'prop-types';

const LoadingGrid = ({ size }) => (
  <svg
    width={size}
    height={size}
    className="lds-rolling"
    style={{ background: '0 0' }}
    preserveAspectRatio="xMidYMid"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      transform="rotate(161.89 50 50)"
      cx="50"
      cy="50"
      r="35"
      fill="none"
      stroke="#f47420"
      strokeDasharray="164.93361431346415 56.97787143782138"
      strokeWidth="3"
    >
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="linear"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
    </circle>
    <g transform="matrix(.18489 0 0 .18489 -5.3352 -4.4506)">
      <path
        style={{ fill: '#f47421' }}
        transform="translate(-255.5,-151)"
        d="m671.89 432.67-181.62-94.18a122.58 122.58 0 0 1 181.62 94.18z"
      />
      <path
        style={{ fill: '#f47421' }}
        transform="translate(-255.5,-151)"
        d="m427.46 445.5a122.14 122.14 0 0 1 37.48-88.2v176.39a122.12 122.12 0 0 1-37.48-88.19z"
      />
      <path
        style={{ fill: '#f47421' }}
        transform="translate(-255.5,-151)"
        d="m490.75 552.77 180.63-90.32a122.58 122.58 0 0 1-180.63 90.32z"
      />
      <polygon
        style={{ fill: '#004b8d' }}
        points="219.9 399.86 219.9 189.33 231 195.08 413.83 289.89 426.6 296.51 413.63 303 231.33 394.13"
      />
      <polygon
        style={{ fill: '#f5f8fd' }}
        className="cls-3"
        points="287.81 249.38 297.89 278.61 331.15 278.61 303.93 298.77 314.01 330.02 287.81 310.87 260.59 330.02 270.67 298.77 244.46 278.61 276.72 278.61"
      />
    </g>
  </svg>
);

LoadingGrid.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

LoadingGrid.defaultProps = {
  color: '#3385FF',
  size: 100,
};

export default LoadingGrid;
